import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  base_url = environment.api;

  constructor(public http: HttpClient) {  }

  public login(user_name: string, password: string): Observable<any> {

    const headers : HttpHeaders = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json' );

    const options = { headers: headers };

    const params = {
      user_email: user_name,
      user_password: btoa(password),
      user_origin: 'L+'
    };

    const url = this.base_url + 'user/auth';

    return this.http.post(url, params, options);
  }

  public isAuthenticated() : boolean{
    console.log(localStorage);
    return localStorage['user_email'] !== undefined && localStorage['user_email'] !== null;
  }
}
