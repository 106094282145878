<ion-app>
  <ion-menu side="start" menuId="m1" contentId="main">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title> XRIOT </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-menu-toggle>
        <ion-list>
          <ion-item *ngFor="let item of appPages" lines="none" [routerLink]="[item.url]">
            <ion-icon name="{{item.icon}}" slot="start"></ion-icon>
            <ion-label> {{item.title}} </ion-label>
          </ion-item>
          <ion-item lines="none" (click)="doLogout()" routerLink="login">
            <ion-icon name="exit-outline" slot="start"></ion-icon>
            <ion-label> Sair </ion-label>
          </ion-item>
        </ion-list>
      </ion-menu-toggle>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
