import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private session!: boolean;

  public appPages = [
    /*
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'home'
    },*/
    {
      title: 'Mapa',
      url: '/home',
      icon: 'map-outline',
    },
    {
      title: 'Meus IOTracers',
      url: '/list',
      icon: 'list-outline',
    },
    {
      title: 'Meus ENVNIs',
      url: '/envni-list',
      icon: 'wifi-outline',
    },
    {
      title: 'Histórico',
      url: '/data',
      icon: 'file-tray-stacked-outline',
    },
    {
      title: 'Alarmes',
      url: '/alarm',
      icon: 'alert-circle-outline',
    },
    {
      title: 'Gráficos',
      url: '/chart',
      icon: 'bar-chart-outline',
    },
  ];

  constructor(
    private router: Router
  ) {}

  doLogout(){
    localStorage.clear();
    this.session = false;
    this.router.navigateByUrl('login');

    console.log('Clicked');
  }
}
